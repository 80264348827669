import { createApp } from 'vue/dist/vue.esm-bundler';

import "bootstrap"
import "trix"
import "@rails/actiontext"
import '../plugins/main.js'

import AdminCourseLesson from "../components/AdminCourseLesson.vue"

import * as Turbo from '@hotwired/turbo'
Turbo.start()

document.addEventListener("turbo:load", function() {
  const app = createApp({
    data() {
      return {
        course: 'Join 81,536+ developers learning, building, and deploying Ruby on Rails apps for their ideas, products, and businesses.'
      }
    }
  })

  app.component('admin-course-lesson', AdminCourseLesson)
  app.mount('#app');
  console.log("app", app);

  // Lesson show page reply form
  document.querySelectorAll('.reply-link').forEach(function(link) {
    link.addEventListener('click', function(event) {
      event.preventDefault();
      var replyForm = document.getElementById(link.dataset.replyTarget);
      if (replyForm.style.display === "none") {
        replyForm.style.display = "block";
      } else {
        replyForm.style.display = "none";
      }
    });
  });

})


// Example: Load Rails libraries in Vite.
//

//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.scss'
